import { Form, Modal, Input, message } from "antd";
import { createUser } from "./actions";

export const AddUserModal = ({ onOk, onCancel }) => {
    const [form] = Form.useForm();

    const onAdd = async () => {
        try {
            let user = await form.validateFields();
            message.loading('Creating user, please wait...', 0);
            let { success, errMsg } = await createUser(user);
            message.destroy();
            if (success) {
                message.success("User created");
                onOk();
            }
            else message.error(errMsg);
        } catch (err) {
            message.destroy();
            console.log(err);
            message.error(err?.errorFields?.length ? 'Please fill mandatory fields' : 'User creation failed.');
        }
    }

    return (
        <Modal title='Add User' visible={true} onOk={onAdd} onCancel={onCancel} okText='Add' maskClosable={false}>
            <Form form={form} labelCol={{ span: 9 }} wrapperCol={{ span: 15 }} >
                <Form.Item label="First Name" name='firstName' rules={[{ required: true, message: 'Please enter first name!' }]} >
                    <Input />
                </Form.Item>
                <Form.Item label="Last Name" name='lastName' rules={[{ required: true, message: 'Please enter last name!' }]} >
                    <Input />
                </Form.Item>
                <Form.Item label="Email" name='email' rules={[{ required: true, type: 'email', message: 'Please enter valid email address!' }]} >
                    <Input placeholder='email' />
                </Form.Item>
            </Form>
        </Modal>
    )
}