import { Layout, Menu, Row, Col, Button } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined, UserOutlined, VideoCameraOutlined, LogoutOutlined, TeamOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { logout } from '../login/actions';
import { useDispatch } from 'react-redux';
import { UsersPage } from '../users/UsersPage';
import { AdsPage } from '../ads/AdsPage';
import { CongressPage } from '../congress/CongressPage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUtensils } from '@fortawesome/free-solid-svg-icons'
import { RestaurantsPage } from '../food/RestaurantsPage';

const { Header, Sider, Content } = Layout;
const userMenu = 'userMenu';
const adMenu = 'adMenu';
const congressMenu = 'congressMenu';
const foodMenu = 'foodMenu';

export const DashboardPage = () => {
    const [collapsed, setCollapsed] = useState(false);
    const [selectedMenu, setSelectedMenu] = useState(userMenu)
    const dispatch = useDispatch();

    return (
        <Layout style={{ minHeight: "100vh" }}>
            <Sider trigger={null} collapsible collapsed={collapsed}>
                <Row id="logo" align="middle" justify="center"><h1 style={{ color: "white" }}>C S S L</h1></Row>
                <Menu theme="dark" mode="inline" selectedKeys={[selectedMenu]} onSelect={e => setSelectedMenu(e.key)}>
                    <Menu.Item key={userMenu} icon={<UserOutlined />}>
                        Users
                    </Menu.Item>
                    <Menu.Item key={adMenu} icon={<VideoCameraOutlined />}>
                        Advertisements
                    </Menu.Item>
                    <Menu.Item key={congressMenu} icon={<TeamOutlined />}>
                        Congress
                    </Menu.Item>
                    <Menu.Item key={foodMenu} icon={<FontAwesomeIcon icon={faUtensils} />}>
                        Food
                    </Menu.Item>
                </Menu>
            </Sider>
            <Layout>
                <Header className="site-layout-background" style={{ padding: 0 }}>
                    <Row>
                        <Col>
                            {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                id: 'menu-toggle-trigger',
                                onClick: () => setCollapsed(prev => !prev),
                            })}
                        </Col>
                        <Col><h1>{selectedMenu === userMenu ? 'Users' : selectedMenu === adMenu ? 'Ads' : selectedMenu === congressMenu ? 'Congress' : 'Food'}</h1></Col>
                        <Col flex="auto" style={{ textAlign: 'end', marginRight: '20px' }}>
                            <Button icon={<LogoutOutlined />} onClick={() => logout(dispatch)}>Logout</Button>
                        </Col>
                    </Row>
                </Header>
                <Content
                    className="site-layout-background"
                    style={{
                        margin: '24px 16px',
                        padding: 24,
                        minHeight: 280,
                    }}
                >
                    {selectedMenu === userMenu && <UsersPage />}
                    {selectedMenu === adMenu && <AdsPage />}
                    {selectedMenu === congressMenu && <CongressPage />}
                    {selectedMenu === foodMenu && <RestaurantsPage />}
                </Content>
            </Layout>
        </Layout >
    );
}