import axios from "axios";


export const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export const getFileExtension = (filename) => {
    return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename)[0] : undefined;
}

export const uploadFile = async (url, file, contentType) => {
    return await axios.put(url,
        file,
        {
            headers: {
                'Content-Type': contentType
            }
        }
    );
}