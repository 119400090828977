import { Form, Modal, Input, message, Upload, InputNumber } from "antd";
import { useEffect, useState } from "react";
import { addMenuItem, getSignedUrl } from "./actions";
import { PlusOutlined } from '@ant-design/icons';
import { getBase64, getFileExtension, uploadFile } from "../../utils/commonUtils";
import { moneyRegex } from "./AddRestaurantModal";

export const AddMenuItemModal = ({ onOk, onCancel, restaurantId, menuItem }) => {
    const [form] = Form.useForm();
    const [imageFile, setImageFile] = useState();
    const [imageUrl, setImageUrl] = useState();
    const isEdit = menuItem !== undefined;

    useEffect(() => {
        if (isEdit) setImageUrl(menuItem.imageLink);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onAdd = async () => {
        try {
            let { name, price } = await form.validateFields();
            message.loading(`${isEdit ? 'Updating' : 'Adding'} menu item, please wait...`, 0);
            let imageLink, id;
            if (imageFile) {
                let { putUrl, getUrl, itemId } = await getSignedUrl(imageFile.type, getFileExtension(imageFile.name), false, undefined, menuItem?.itemId);
                await uploadFile(putUrl, imageFile, imageFile.type);
                imageLink = getUrl;
                id = itemId;
            }
            let { success, errMsg } = await addMenuItem({ ...(isEdit ? menuItem : {}), itemId: id || menuItem?.itemId, restaurantId, name, price: price * 100, imageLink: imageLink || imageUrl, isUpdate: isEdit });
            message.destroy();
            if (success) {
                message.success(`Item ${isEdit ? 'updated' : 'added'}`);
                onOk();
            }
            else message.error(errMsg);
        } catch (err) {
            message.destroy();
            console.log(err);
            message.error(err?.errorFields?.length ? 'Please fill mandatory fields' : 'Item adding failed.');
        }
    }
    const beforeUpload = (file, isBottom) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
            return false;
        }
        getBase64(file).then(url => setImageUrl(url));
        setImageFile(file);
        return false;
    }

    return (
        <Modal title={isEdit ? 'Edit Menu Item' : 'Add Menu Item'} visible={true} onOk={onAdd} onCancel={onCancel} okText={`${isEdit ? 'Save' : 'Add'}`} maskClosable={false}>
            <Form form={form} labelCol={{ span: 9 }} wrapperCol={{ span: 15 }} initialValues={isEdit ? { ...menuItem, price: menuItem.price / 100 } : {}} >
                <Form.Item label="Food Item" name='name' rules={[{ required: true, message: 'Please enter food item!' }]} >
                    <Input placeholder="Chicken Sandwich" />
                </Form.Item>
                <Form.Item label="Price" name='price' rules={[{ required: true, message: 'Please enter price!', pattern: moneyRegex }]} initialValue={0.00}>
                    <InputNumber addonBefore='Rs.' precision={2} />
                </Form.Item>
                <Form.Item label="Food Image">
                    <Upload
                        name="avatar"
                        listType="picture-card"
                        showUploadList={false}
                        beforeUpload={file => beforeUpload(file, false)}
                    >
                        {imageUrl ? <img src={imageUrl} alt="img" style={{ width: '100%' }} /> :
                            <div >
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>Upload</div>
                            </div>}
                    </Upload>
                </Form.Item>
            </Form>
        </Modal >
    )
}