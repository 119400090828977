import { Table, Row, Spin, Col, Button, Tag, Modal, message, Space, Input } from "antd";
import moment from "moment";
import { useEffect, useState } from "react"
import { disableUser, enableUser, getUsers, makeAdmin, makeGuest, removeAdmin, removeGuest } from "./actions";
import { AddUserModal } from "./AddUserModal";
import { ExclamationCircleOutlined } from '@ant-design/icons';

export const UsersPage = () => {
    const [users, setUsers] = useState();
    const [addUser, setAddUser] = useState(false);
    const [searchText, setSearchText] = useState();

    useEffect(() => {
        getUsers().then(setUsers);
    }, []);

    if (!users) return <Spin size="large" />
    const columns = [
        {
            title: 'First Name',
            dataIndex: 'Attributes',
            render: userAttributes => userAttributes.find(({ Name }) => Name === 'name')?.Value
        },
        {
            title: 'Last Name',
            dataIndex: 'Attributes',
            render: userAttributes => userAttributes.find(({ Name }) => Name === 'family_name')?.Value
        },
        {
            title: 'Email',
            dataIndex: 'Username',
            key: 'Username'
        },
        {
            title: 'Created At',
            dataIndex: 'UserCreateDate',
            key: 'UserCreateDate',
            render: text => moment(text).format('YYYY-MM-DD hh:mm A'),
        },
        {
            title: 'Status',
            dataIndex: 'Enabled',
            key: 'Enabled',
            render: (enabled, user) => <> {enabled ? <Tag color="success">Enabled</Tag> : <Tag color="error">Disabled</Tag>}
                {user.UserStatus !== "FORCE_CHANGE_PASSWORD" && <Tag color="processing">Registered</Tag>}
            </>
        },
        {
            title: 'Groups',
            dataIndex: 'groups',
            key: 'groups',
            render: (groups, user) => <Row>
                {user.isAdmin && <Tag color="processing">Admin</Tag>}
                {user.isGuest && <Tag color="processing">Guest</Tag>}
                {!user.isAdmin && !user.isGuest && <Tag color="processing">General</Tag>}
            </Row>
        },
        {
            title: 'Action',
            key: 'Enabled',
            dataIndex: 'Enabled',
            render: (enabled, user) =>
                <Space>
                    {enabled && <Button onClick={() => onDisable(user.Username)} type="primary" size='small' danger>Disable</Button>}
                    {!enabled && <Button onClick={() => onEnable(user.Username)} type="primary" size='small'>Enable</Button>}
                    {user.isAdmin && <Button onClick={() => onRemoveAdmin(user.Username)} type="primary" size='small'>Remove from Admin</Button>}
                    {!user.isAdmin && !user.isGuest && <Button onClick={() => onSetAdmin(user.Username)} type="primary" size='small'>Make Admin</Button>}
                    {!user.isAdmin && !user.isGuest && <Button onClick={() => onMakeGuest(user.Username)} type="primary" size='small'>Make Guest</Button>}
                    {user.isGuest && <Button onClick={() => onRemoveGuest(user.Username)} type="primary" size='small'>Set General User</Button>}
                </Space>
        },
    ];

    const onEnable = (username) => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: 'Do you want to enable this user?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: async () => {
                message.loading('Updating user, please wait...', 0);
                await enableUser(username);
                message.destroy();
                message.success("User enabled");
                getUsers().then(setUsers);
            },
        });
    }

    const onDisable = (username) => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: 'Do you want to disable this user?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: async () => {
                message.loading('Updating user, please wait...', 0);
                await disableUser(username);
                message.destroy();
                message.success("User disabled");
                getUsers().then(setUsers);
            },
        });
    }

    const onSetAdmin = (username) => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: 'Do you want to make this user admin?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: async () => {
                message.loading('Updating user, please wait...', 0);
                await makeAdmin(username);
                message.destroy();
                message.success("User is now admin");
                getUsers().then(setUsers);
            },
        });
    }

    const onRemoveAdmin = (username) => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: `Do you want to remove this user's admin privileges?`,
            okText: 'Yes',
            cancelText: 'No',
            onOk: async () => {
                message.loading('Updating user, please wait...', 0);
                await removeAdmin(username);
                message.destroy();
                message.success("Admin privileges removed from user");
                getUsers().then(setUsers);
            },
        });
    }

    const onMakeGuest = (username) => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: 'Do you want to set this user as guest user?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: async () => {
                message.loading('Updating user, please wait...', 0);
                await makeGuest(username);
                message.destroy();
                message.success("User in now a guest user");
                getUsers().then(setUsers);
            },
        });
    }

    const onRemoveGuest = (username) => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: 'Do you want to set this user as a general user?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: async () => {
                message.loading('Updating user, please wait...', 0);
                await removeGuest(username);
                message.destroy();
                message.success("User in now a general user");
                getUsers().then(setUsers);
            },
        });
    }
    return <>
        {addUser && <AddUserModal onOk={() => { getUsers().then(setUsers); setAddUser(false) }} onCancel={() => setAddUser(false)} />}
        <Row>
            <Col flex="auto" style={{ textAlign: 'end', marginRight: '20px' }}>
                <Input.Search placeholder="input search text" onSearch={setSearchText} style={{ width: 200 }} />&nbsp;
                <Button type="primary" onClick={() => setAddUser(true)}>Add User</Button>
            </Col>
        </Row>
        <br />
        <Row>
            <Table columns={columns}
                dataSource={searchText ? users.filter(u => `${u.Attributes.find(({ Name }) => Name === 'name')?.Value}${u.Attributes.find(({ Name }) => Name === 'family_name')?.Value}${u.Username}`.toLowerCase().includes(searchText.toLowerCase())) : users} style={{ width: "100%" }} />
        </Row>
    </>
}