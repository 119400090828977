import { Upload, Button, message, Row, Col, Table, Spin, Modal } from "antd";
import { useEffect, useState } from "react"
import { deleteMaterialFile, getMaterials, getSignedUrl } from "./actions";
import { uploadFile } from "../ads/AddAdModal";
import path from "path";
import { ExclamationCircleOutlined } from '@ant-design/icons';

export const Materials = () => {
    const [materials, setMaterials] = useState();
    useEffect(() => {
        getMaterials().then(setMaterials);
    }, []);

    const getUploadProps = () => ({
        beforeUpload: async (file) => {
            if (file.type !== 'application/pdf') {
                message.error('You can only upload PDF file!');
            } else {
                message.loading('Uploading file', 0);
                let { putUrl } = await getSignedUrl(`materials/${path.basename(file.name, '.pdf')}`);
                await uploadFile(putUrl, file, file.type);
                message.destroy();
                message.success('File uploaded');
                getMaterials().then(setMaterials);
            }
            return false;
        },
        showUploadList: false,
        accept: 'application/pdf'
    });

    const onDelete = (fileName) => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: 'Do you want delete this file?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: async () => {
                message.loading('Deleting file, please wait...', 0);
                await deleteMaterialFile(fileName);
                message.destroy();
                message.success("File deleted");
                getMaterials().then(setMaterials);
            },
        });
    }

    return <>
        <Row>
            <Col flex="auto" style={{ textAlign: 'end', marginRight: '20px', marginBottom: '20px' }}>
                <Upload {...getUploadProps()}>
                    <Button type="primary">Upload New File</Button>
                </Upload>
            </Col>
        </Row>
        {materials === undefined && <Spin size="large" />}
        {materials !== undefined && <Table
            columns={[
                {
                    title: 'File',
                    dataIndex: 'fileName',
                    key: 'fileName',
                    align: 'center',
                },
                {
                    title: 'View',
                    dataIndex: 'link',
                    key: 'link',
                    align: 'center',
                    render: (link) => link && <a href={link} target='_blank' rel="noreferrer">View File</a>
                },
                {
                    title: 'Action',
                    dataIndex: 'title',
                    key: 'title',
                    align: 'center',
                    render: (text, item) => (
                        <Button onClick={() => onDelete(item.fileName)} type="primary" danger size='small'>Delete</Button>
                    ),
                }
            ]}
            dataSource={materials}

        />}

    </>
}
