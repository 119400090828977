import { API } from 'aws-amplify';
import { APIS } from '../../utils/apiUtils';

export const getUsers = async () => {
  const users = await API.get(APIS.USER_API, '/users');
  return users;
};

export const createUser = async (user) => {
  const resp = await API.post(APIS.USER_API, '/user', { body: user });
  return resp;
};

export const enableUser = async (username) => {
  await API.post(APIS.USER_API, '/enable-user', { body: { username } });
};

export const disableUser = async (username) => {
  await API.post(APIS.USER_API, '/disable-user', { body: { username } });
};

export const makeAdmin = async (username) => {
  await API.post(APIS.USER_API, '/set-admin', { body: { username } });
};

export const removeAdmin = async (username) => {
  await API.post(APIS.USER_API, '/remove-admin', { body: { username } });
};

export const makeGuest = async (username) => {
  await API.post(APIS.USER_API, '/set-guest', { body: { username } });
};

export const removeGuest = async (username) => {
  await API.post(APIS.USER_API, '/remove-guest', { body: { username } });
};
