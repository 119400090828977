import { Upload, Button, message, Switch, Table, Spin } from "antd";
import { useEffect, useState } from "react"
import { getSignedUrl, getTabStatus, updateTabStatus } from "./actions";
import { Tabs } from 'antd';
import { uploadFile } from "../ads/AddAdModal";
import { Programme } from "./Programme";
import { Materials } from "./Materials";


const { TabPane } = Tabs;

export const CongressPage = () => {
    const [tabStatus, setTabStatus] = useState();
    useEffect(() => {
        getTabStatus().then(setTabStatus);
    }, []);

    // if (!users) return <Spin size="large" />

    const getUploadProps = (fileName) => ({
        beforeUpload: async (file) => {
            if (file.type !== 'application/pdf') {
                message.error('You can only upload PDF file!');
            } else {
                message.loading('Uploading file', 0);
                let { putUrl } = await getSignedUrl(fileName);
                await uploadFile(putUrl, file, file.type);
                message.destroy();
                message.success('File uploaded');
            }
            return false;
        },
        showUploadList: false,
        accept: 'application/pdf'
    });

    return <>
        <Tabs defaultActiveKey="1">
            <TabPane tab="Files" key="1">
                {tabStatus === undefined && <Spin size="large" />}
                {tabStatus !== undefined && <Table
                    columns={[
                        {
                            title: 'File',
                            dataIndex: 'title',
                            key: 'title',
                            align: 'center',
                        },
                        {
                            title: 'Show on Mobile App',
                            dataIndex: 'file',
                            key: 'file',
                            align: 'center',
                            render: (file) => <Switch onChange={(enabled) => updateTabStatus(file, enabled)} defaultChecked={tabStatus.find(t => t.name === file)?.enabled} />
                        },
                        {
                            title: 'View',
                            dataIndex: 'link',
                            key: 'link',
                            align: 'center',
                            render: (link) => link && <a href={link} target='_blank' rel="noreferrer">View File</a>
                        },
                        {
                            title: 'Upload',
                            dataIndex: 'file',
                            key: 'file',
                            align: 'center',
                            render: (file, item) => item.link && <Upload {...getUploadProps(file)}>
                                <Button>Upload New File</Button>
                            </Upload>
                        }
                    ]}
                    dataSource={
                        [
                            { title: 'About', file: 'about', link: "https://cssl-data.s3.us-west-2.amazonaws.com/congress/about.pdf" },
                            { title: 'Speaker Profiles', file: 'profiles', link: "https://cssl-data.s3.us-west-2.amazonaws.com/congress/profiles.pdf" },
                            { title: 'Biographies', file: 'biographies', link: "https://cssl-data.s3.us-west-2.amazonaws.com/congress/biographies.pdf" },
                            { title: 'Material', file: 'material' },
                            { title: 'Programme', file: 'programme' },
                        ]
                    }

                />}
            </TabPane>
            <TabPane tab="Programme" key="2">
                <Programme />
            </TabPane>
            <TabPane tab="Materials" key="3">
                <Materials />
            </TabPane>
        </Tabs>
    </>
}
