import { message } from 'antd';
import { API } from 'aws-amplify';
import { APIS } from '../../utils/apiUtils';

export const getSignedUrl = async (fileName) => {
  let resp = await API.get(APIS.CONGRESS_API, `/put-signed-url`, { queryStringParameters: { fileName } });
  return resp;
};

export const getTabStatus = async () => {
  let resp = await API.get(APIS.CONGRESS_API, `/tab-status`);
  return resp;
};

export const updateTabStatus = async (name, enabled) => {
  message.loading('Updating status...', 0);
  await API.post(APIS.CONGRESS_API, `/tab-status`, { body: { name, enabled } });
  message.destroy();
  message.success('Status updated');
};

export const getCongressEvents = async () => {
  let resp = await API.get(APIS.CONGRESS_API, `/congress-events`);
  return resp;
};

export const addCongressEvent = async (event) => {
  const resp = await API.post(APIS.CONGRESS_API, '/congress-event', { body: event });
  return resp;
};

export const deleteCongressEvent = async (startDate_startTime) => {
  const resp = await API.del(APIS.CONGRESS_API, '/congress-event', { body: { startDate_startTime } });
  return resp;
};

export const getMaterials = async () => {
  let resp = await API.get(APIS.CONGRESS_API, `/material-files`);
  return resp;
};

export const deleteMaterialFile = async (fileName) => {
  const resp = await API.del(APIS.CONGRESS_API, '/material-file', { body: { fileName } });
  return resp;
};