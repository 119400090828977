import { API } from 'aws-amplify';
import { APIS } from '../../utils/apiUtils';

export const getAds = async () => {
  const users = await API.get(APIS.AD_API, '/ads');
  return users;
};

export const getAdCategories = async (forAdmin) => {
  const categories = await API.get(APIS.AD_API, '/ad-categories', forAdmin ? { queryStringParameters: { forAdmin } } : {});
  return categories;
};

export const getSignedUrl = async (fileType, fileExtension, isMain, adId) => {
  let resp = await API.get(APIS.AD_API, `/ad-signed-url`, { queryStringParameters: { fileType, fileExtension, isMain, adId } });
  return resp;
};

export const createAd = async (ad) => {
  const resp = await API.post(APIS.AD_API, '/ad', { body: ad });
  return resp;
};

export const createCategory = async (category) => {
  let resp = await API.post(APIS.AD_API, '/ad-category', { body: { category } });
  return resp;
};

export const setAdInactive = async (adId) => {
  await API.post(APIS.AD_API, '/ad-inactive', { body: { adId } });
};

export const archiveCategory = async (categoryId) => {
  await API.put(APIS.AD_API, '/ad-category-archive', { body: { categoryId } });
};

export const unarchiveCategory = async (categoryId) => {
  await API.put(APIS.AD_API, '/ad-category-unarchive', { body: { categoryId } });
};

export const createVendor = async (vendor) => {
  let resp = await API.post(APIS.AD_API, '/vendor', { body: { vendor } });
  return resp;
};

export const getVendors = async (forAdmin) => {
  const vendors = await API.get(APIS.AD_API, '/vendors', forAdmin ? { queryStringParameters: { forAdmin } } : {});
  return vendors;
};

export const archiveVendor = async (vendorId) => {
  await API.put(APIS.AD_API, '/vendor-archive', { body: { vendorId } });
};

export const unarchiveVendor = async (vendorId) => {
  await API.put(APIS.AD_API, '/vendor-unarchive', { body: { vendorId } });
};


