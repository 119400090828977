import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import Amplify from 'aws-amplify';
import LoginPage from './views/login/LoginPage';
import { AWS_CONFIG } from './configs/app-config';
import { checkIsLoggedIn } from './views/login/actions';
import Loading from './components/Loading';
import { initApi } from './utils/apiUtils';
import { useDispatch, useSelector } from 'react-redux';
import 'antd/dist/antd.css';
import './App.css';
import { DashboardPage } from './views/dashboard/DashboardPage';

Amplify.configure(AWS_CONFIG);
initApi();

function App() {
  const userId = useSelector((state) => state.userData.userId);
  const dispatch = useDispatch();

  useEffect(() => {
    checkIsLoggedIn(dispatch);
  }, [dispatch]);

  if (userId === undefined) {
    return <Loading />;
  }

  return (
    <Router>
      <Switch>
        {!userId && <Route path="/login"><LoginPage /></Route>}
        {!userId && <Route render={({ location }) => <Redirect to={{ pathname: '/login', state: { from: location } }} />} />}
        {userId && <Route exact path="/"> <DashboardPage /></Route>}
        {userId && <Redirect to="/" />}
      </Switch>
    </Router>
  );
}

export default App;
