import { Table, Row, Col, Button, Modal, message, Switch } from "antd";
import { useEffect, useState } from "react"
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { AddVendorModal } from "./AddVendorModal";
import { archiveVendor, getVendors, unarchiveVendor } from "./actions";

export const VendorsModal = ({ onOk, onCancel }) => {
    const [vendors, setVendors] = useState();
    const [addVendor, setAddVendor] = useState(false);
    const [showActive, setShowActive] = useState(true);
    useEffect(() => {
        getVendors(true).then(setVendors);
    }, []);

    const onArchive = (vendorId) => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: 'Do you want archive this vendor?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: async () => {
                message.loading('Archiving vendor, please wait...', 0);
                await archiveVendor(vendorId);
                message.destroy();
                message.success("Vendor archived");
                getVendors(true).then(setVendors);
            },
        });
    }

    const onUnarchive = (vendorId) => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: 'Do you want unarchive this vendor?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: async () => {
                message.loading('Unarchiving vendor, please wait...', 0);
                await unarchiveVendor(vendorId);
                message.destroy();
                message.success("Vendor unarchived");
                getVendors(true).then(setVendors);
            },
        });
    }

    return (
        <Modal title='Vendors' visible={true} onOk={onOk} onCancel={onCancel} okText='OK'>
            {addVendor && <AddVendorModal onOk={() => { setAddVendor(false); getVendors(true).then(setVendors); }}
                onCancel={() => setAddVendor(false)} />}
            <Row>
                <Col>
                    <Switch checked={showActive} onChange={setShowActive} /> Show Active Vendors Only
                </Col>
                <Col flex="auto" style={{ textAlign: 'end', marginRight: '20px' }}>
                    <Button type="primary" onClick={() => setAddVendor(true)}>Add</Button>
                </Col>
            </Row>
            <br />
            <Table
                columns={[
                    {
                        title: 'Vendor',
                        dataIndex: 'vendor',
                        key: 'vendor',
                        align: 'center',
                    },
                    {
                        title: 'Status',
                        dataIndex: 'isArchived',
                        key: 'isArchived',
                        align: 'center',
                        render: isArchived => isArchived ? 'Inactive' : 'Active'
                    },
                    {
                        title: 'Action',
                        dataIndex: 'vendorId',
                        key: 'vendorId',
                        align: 'center',
                        render: (vendorId, item) => (
                            !item.isArchived ? <Button onClick={() => onArchive(vendorId)} type="primary" danger size='small'>Archive</Button>
                                : <Button onClick={() => onUnarchive(vendorId)} type="primary" danger size='small'>Unarchive</Button>
                        ),
                    }
                ]}
                dataSource={showActive ? vendors?.filter(c => !c.isArchived) || [] : vendors || []}
                loading={vendors === undefined}
            />
        </Modal>)
}