import Amplify, { Auth } from 'aws-amplify';

export const APIS = {
  USER_API: 'USER_API',
  AD_API: 'AD_API',
  CONGRESS_API: 'CONGRESS_API',
  FOOD_API: 'FOOD_API',
};

const authHeader = async () => ({ Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` });

export const initApi = () => {
  Amplify.configure({
    API: {
      endpoints: [
        {
          name: APIS.USER_API,
          endpoint: `https://pl5aczq0ad.execute-api.us-west-2.amazonaws.com/prod`,
          custom_header: authHeader,
        },
        {
          name: APIS.AD_API,
          endpoint: `https://j3ho5c8ps1.execute-api.us-west-2.amazonaws.com/prod`,
          custom_header: authHeader,
        },
        {
          name: APIS.CONGRESS_API,
          endpoint: `https://1pwng75tog.execute-api.us-west-2.amazonaws.com/prod`,
          custom_header: authHeader,
        },
        {
          name: APIS.FOOD_API,
          endpoint: `https://d6hhariisc.execute-api.us-west-2.amazonaws.com/prod`,
          custom_header: authHeader,
        },
      ],
    },
  });
};
