import { API } from 'aws-amplify';
import { APIS } from '../../utils/apiUtils';

export const getRestaurants = async () => {
  const restaurants = await API.get(APIS.FOOD_API, '/restaurants', { queryStringParameters: { forAdmin: true } });
  return restaurants;
};

export const getSignedUrl = async (fileType, fileExtension, isRestaurant, restaurantId, itemId) => {
  let resp = await API.get(APIS.FOOD_API, `/signed-url`, { queryStringParameters: { fileType, fileExtension, isRestaurant, restaurantId, itemId } });
  return resp;
};

export const addRestaurant = async (restaurant) => {
  const resp = await API.post(APIS.FOOD_API, '/restaurant', { body: restaurant });
  return resp;
};

export const getMenuItems = async (restaurantId) => {
  const menuItems = await API.get(APIS.FOOD_API, '/menu', { queryStringParameters: { restaurantId, forAdmin: true } });
  return menuItems;
};

export const addMenuItem = async (item) => {
  const resp = await API.post(APIS.FOOD_API, '/menu-item', { body: item });
  return resp;
};

export const archiveRestaurant = async (restaurantId) => {
  const resp = await API.put(APIS.FOOD_API, '/archive-restaurant', { body: { restaurantId } });
  return resp;
};

export const unarchiveRestaurant = async (restaurantId) => {
  const resp = await API.put(APIS.FOOD_API, '/unarchive-restaurant', { body: { restaurantId } });
  return resp;
};

export const archiveMenuItem = async (restaurantId, itemId) => {
  const resp = await API.put(APIS.FOOD_API, '/archive-menu-item', { body: { restaurantId, itemId } });
  return resp;
};

export const unarchiveMenuItem = async (restaurantId, itemId) => {
  const resp = await API.put(APIS.FOOD_API, '/unarchive-menu-item', { body: { restaurantId, itemId } });
  return resp;
};