import { Table, Row, Spin, Col, Button, Tag, Modal, message, Switch, Avatar } from "antd";
import moment from "moment";
import { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import { archiveRestaurant, getRestaurants, unarchiveRestaurant } from "./actions";
import { AddRestaurantModal } from "./AddRestaurantModal";
import { RestaurantDetailsModal } from "./RestaurantDetailsModal";
import { ExclamationCircleOutlined } from '@ant-design/icons';


export const RestaurantsPage = () => {
    const [restaurants, setRestaurants] = useState();
    const [addRestaurant, setAddRestaurant] = useState(false);
    const [showRestaurantDetails, setShowRestaurantDetails] = useState();
    const [showActive, setShowActive] = useState(true);
    const [editRestaurant, setEditRestaurant] = useState();

    useEffect(() => {
        getRestaurants().then(setRestaurants);
    }, []);

    if (restaurants === undefined) return <Spin size="large" />
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (name, restaurant) => <Link to="#" onClick={() => setShowRestaurantDetails(restaurant)}><Avatar shape="square" size={64} src={restaurant.imageLink} style={{ marginRight: 10 }} />{name}</Link>
        },
        {
            title: 'Contact No',
            dataIndex: 'contactNo',
            key: 'contactNo',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Open From',
            dataIndex: 'openFrom',
            key: 'openFrom',
            render: openFrom => moment(openFrom, 'HH:mm').format('hh:mm A'),
        },
        {
            title: 'Open Till',
            dataIndex: 'openTo',
            key: 'openTo',
            render: openTo => moment(openTo, 'HH:mm').format('hh:mm A'),
        },
        {
            title: 'Status',
            dataIndex: 'isArchived',
            key: 'isArchived',
            render: (isArchived) => isArchived ? <Tag color="error">Archived</Tag> : <Tag color="processing">Active</Tag>
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, restaurant) => (
                <Row>
                    <Button onClick={() => setEditRestaurant(restaurant)} type="primary" size='small' style={{ marginRight: 10 }}>Edit</Button>
                    <Button onClick={() => restaurant.isArchived ? onRestore(restaurant.restaurantId) : onArchive(restaurant.restaurantId)} type="primary" danger size='small' >{restaurant.isArchived ? 'Restore' : 'Archive'}</Button>
                </Row>

            ),
        },
    ];

    const onArchive = (restaurantId) => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: 'Do you want to archive this restaurant?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: async () => {
                message.loading('Archiving restaurant, please wait...', 0);
                await archiveRestaurant(restaurantId);
                message.destroy();
                message.success("Restaurant Archive");
                getRestaurants().then(setRestaurants);
            },
        });
    }

    const onRestore = (restaurantId) => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: 'Do you want to restore this restaurant?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: async () => {
                message.loading('Restoring restaurant, please wait...', 0);
                await unarchiveRestaurant(restaurantId);
                message.destroy();
                message.success("Restaurant Restored");
                getRestaurants().then(setRestaurants);
            },
        });
    }

    return <>
        {addRestaurant && <AddRestaurantModal onOk={() => { getRestaurants().then(setRestaurants); setAddRestaurant(false) }} onCancel={() => setAddRestaurant(false)} />}
        {editRestaurant && <AddRestaurantModal onOk={() => { getRestaurants().then(setRestaurants); setEditRestaurant() }} onCancel={() => setEditRestaurant()} restaurant={editRestaurant} />}
        {showRestaurantDetails && <RestaurantDetailsModal onCancel={() => setShowRestaurantDetails()} restaurant={showRestaurantDetails} />}
        <Row>
            <Col>
                <Switch checked={showActive} onChange={setShowActive} /> Show Active Restaurants Only
            </Col>
            <Col flex="auto" style={{ textAlign: 'end', marginRight: '20px' }}>
                <Button type="primary" onClick={() => setAddRestaurant(true)}>New Restaurant</Button>
            </Col>
        </Row>
        <br />
        <Row>
            <Table columns={columns} dataSource={showActive ? restaurants.filter(rest => !rest.isArchived) : restaurants} style={{ width: "100%" }} rowKey="restaurantId" />
        </Row>
    </>
}