import { Modal, Descriptions, Button, Tag, Tabs, Row, Col, Switch, Image, Spin, Avatar, message, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react"
import { archiveMenuItem, getMenuItems, unarchiveMenuItem } from "./actions";
import { AddMenuItemModal } from "./AddMenuItemModal";
import { ExclamationCircleOutlined } from '@ant-design/icons';

export const RestaurantDetailsModal = ({ onCancel, restaurant }) => {
    const [showActive, setShowActive] = useState(true);
    const [addItem, setAddItem] = useState(false);
    const [menuItems, setMenuItems] = useState();
    const [editMenuItem, setEditMenuItem] = useState();


    useEffect(() => {
        getMenuItems(restaurant.restaurantId).then(setMenuItems);
    }, [restaurant]);


    const columns = [
        {
            title: 'Food Item',
            dataIndex: 'name',
            key: 'name',
            render: (name, item) => <>{name}{item.imageLink && <Avatar shape="square" size={64} src={item.imageLink} style={{ marginLeft: 10 }} />}</>
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (price) => `Rs. ${price / 100}`
        },
        {
            title: 'Status',
            dataIndex: 'isArchived',
            key: 'isArchived',
            render: (isArchived) => isArchived ? <Tag color="error">Archived</Tag> : <Tag color="processing">Active</Tag>
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, item) => (

                <Row>
                    <Button onClick={() => setEditMenuItem(item)} type="primary" size='small' style={{ marginRight: 10 }}>Edit</Button>
                    <Button onClick={() => item.isArchived ? onRestore(item.restaurantId, item.itemId) : onArchive(item.restaurantId, item.itemId)} type="primary" danger size='small' >{item.isArchived ? 'Restore' : 'Archive'}</Button>
                </Row>
            ),
        },
    ];

    const onArchive = (restaurantId, itemId) => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: 'Do you want to archive this item?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: async () => {
                message.loading('Archiving menu item, please wait...', 0);
                await archiveMenuItem(restaurantId, itemId);
                message.destroy();
                message.success("Menu item archived");
                getMenuItems(restaurant.restaurantId).then(setMenuItems);
            },
        });
    }

    const onRestore = (restaurantId, itemId) => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: 'Do you want to restore this item?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: async () => {
                message.loading('Restoring menu item, please wait...', 0);
                await unarchiveMenuItem(restaurantId, itemId);
                message.destroy();
                message.success("Menu item restored");
                getMenuItems(restaurant.restaurantId).then(setMenuItems);
            },
        });
    }

    return (
        <Modal title={restaurant.name} visible={true} onOk={onCancel} onCancel={onCancel} width={'90%'} style={{ top: 10 }} maskClosable={false} >
            <Tabs>
                <Tabs.TabPane tab='Details' key='1'>
                    <Descriptions bordered column={1}>
                        <Descriptions.Item label="Image"><Image style={{ width: 100 }} src={restaurant.imageLink} /></Descriptions.Item>
                        <Descriptions.Item label="Name">{restaurant.name}</Descriptions.Item>
                        <Descriptions.Item label="Contact No">{restaurant.contactNo}</Descriptions.Item>
                        <Descriptions.Item label="Email">{restaurant.email}</Descriptions.Item>
                        <Descriptions.Item label="Open From">{moment(restaurant.openFrom, 'HH:mm').format('hh:mm A')}</Descriptions.Item>
                        <Descriptions.Item label="Open Till">{moment(restaurant.openTo, 'HH:mm').format('hh:mm A')}</Descriptions.Item>
                        <Descriptions.Item label="Delivery fee">Rs.{restaurant.deliveryFee}</Descriptions.Item>
                        <Descriptions.Item label="Status">{restaurant.archived ? <Tag color="error">Archived</Tag> : <Tag color="processing">Active</Tag>}</Descriptions.Item>
                    </Descriptions>
                </Tabs.TabPane>
                <Tabs.TabPane tab='Menu' key='2'>
                    {addItem && <AddMenuItemModal restaurantId={restaurant.restaurantId} onOk={() => { getMenuItems(restaurant.restaurantId).then(setMenuItems); setAddItem(false) }} onCancel={() => setAddItem(false)} />}
                    {editMenuItem && <AddMenuItemModal restaurantId={restaurant.restaurantId} onOk={() => { getMenuItems(restaurant.restaurantId).then(setMenuItems); setEditMenuItem() }} onCancel={() => setEditMenuItem()} menuItem={editMenuItem} />}
                    {menuItems === undefined && <Spin size="large" />}
                    {menuItems !== undefined && <> <Row>
                        <Col>
                            <Switch checked={showActive} onChange={setShowActive} /> Show Active Menu Items Only
                        </Col>
                        <Col flex="auto" style={{ textAlign: 'end', marginRight: '20px' }}>
                            <Button type="primary" onClick={() => setAddItem(true)}>Add Item</Button>
                        </Col>

                    </Row>
                        <Row>
                            <Table columns={columns} dataSource={showActive ? menuItems.filter(item => !item.isArchived) : menuItems} style={{ width: "100%" }} rowKey="itemId" />
                        </Row>
                    </>}
                </Tabs.TabPane>
            </Tabs>
        </Modal >
    )
}