import { Form, Modal, Input, message, DatePicker, TimePicker } from "antd";
import { addCongressEvent } from "./actions";
import { ulid } from "ulid";

export const AddEventModal = ({ onOk, onCancel }) => {
    const [form] = Form.useForm();

    const onAdd = async () => {
        try {
            let event = await form.validateFields();
            event.startDate = event.startDate.format('YYYY-MM-DD');
            event.startTime = event.startTime.format('HH:mm:00');
            event.endTime = event.endTime.format('HH:mm:00');
            event.eventType = 'congress';
            event.startDate_startTime = `${event.startDate}_${event.startTime}_${ulid()}`
            message.loading('Adding event, please wait...', 0);
            let { success, errMsg } = await addCongressEvent(event);
            message.destroy();
            if (success) {
                message.success("Event added");
                onOk();
            }
            else message.error(errMsg);
        } catch (err) {
            message.destroy();
            console.log(err);
            message.error(err?.errorFields?.length ? 'Please fill mandatory fields' : 'Error adding event.');
        }
    }

    return (
        <Modal title='Add Programme Event' visible={true} onOk={onAdd} onCancel={onCancel} okText='Add'>
            <Form form={form} labelCol={{ span: 9 }} wrapperCol={{ span: 15 }} >
                <Form.Item label="Date" name='startDate' rules={[{ required: true, message: 'Please select date!' }]} >
                    <DatePicker />
                </Form.Item>
                <Form.Item label="Start Time" name='startTime' rules={[{ required: true, message: 'Please select start time!' }]} >
                    <TimePicker format='hh:mm A' />
                </Form.Item>
                <Form.Item label="End Time" name='endTime' rules={[{ required: true, message: 'Please select end time!' }]} >
                    <TimePicker format='hh:mm A' />
                </Form.Item>
                <Form.Item label="Title" name='title' rules={[{ required: true, message: 'Please enter title!' }]} >
                    <Input />
                </Form.Item>
                <Form.Item label="Description" name='description' >
                    <Input.TextArea />
                </Form.Item>
                <Form.Item label="Location" name='eventLocation' rules={[{ required: true, message: 'Please enter location!' }]} >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    )
}