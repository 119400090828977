import { Button, Table, Row, Col, Modal, message } from "antd";
import { useEffect, useState } from "react"
import { deleteCongressEvent, getCongressEvents } from "./actions";
import { AddEventModal } from "./AddEventModal";
import { ExclamationCircleOutlined } from '@ant-design/icons';

export const Programme = () => {
    const [events, setEvents] = useState();
    const [showAddEvent, setShowAddEvent] = useState(false);
    useEffect(() => {
        getCongressEvents().then(setEvents);
    }, []);

    const onDelete = (startDate_startTime) => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: 'Do you want delete this event?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: async () => {
                message.loading('Deleting event, please wait...', 0);
                await deleteCongressEvent(startDate_startTime);
                message.destroy();
                message.success("Event deleted");
                getCongressEvents().then(setEvents);
            },
        });
    }

    return <>
        {showAddEvent && <AddEventModal onCancel={() => setShowAddEvent(false)} onOk={() => { setShowAddEvent(false); getCongressEvents().then(setEvents); }} />}
        <Row>
            <Col flex="auto" style={{ textAlign: 'end', marginRight: '20px' }}>
                <Button type="primary" onClick={() => setShowAddEvent(true)}>Add Event</Button>
            </Col>
        </Row>
        <br />
        <Table
            columns={[
                {
                    title: 'Date',
                    dataIndex: 'startDate',
                    key: 'startDate',
                    align: 'center',
                },
                {
                    title: 'Start Time',
                    dataIndex: 'startTime',
                    key: 'startTime',
                    align: 'center',
                },
                {
                    title: 'End Time',
                    dataIndex: 'endTime',
                    key: 'endTime',
                    align: 'center',
                },
                {
                    title: 'Title',
                    dataIndex: 'title',
                    key: 'title',
                    align: 'center',
                },
                {
                    title: 'Description',
                    dataIndex: 'description',
                    key: 'description',
                    align: 'center',
                    render: description=> description?.split('\n').map(str => <p>{str}</p>),
                },
                {
                    title: 'Location',
                    dataIndex: 'eventLocation',
                    key: 'eventLocation',
                    align: 'center',
                },
                {
                    title: 'Action',
                    dataIndex: 'title',
                    key: 'title',
                    align: 'center',
                    render: (text, event) => (
                        <Button onClick={() => onDelete(event.startDate_startTime)} type="primary" danger size='small'>Delete</Button>
                    ),
                }
            ]}
            dataSource={events || []}
            loading={events === undefined}
        />
    </>
}
