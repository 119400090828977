import { Table, Row, Col, Button, Modal, message, Switch } from "antd";
import { archiveCategory, getAdCategories, unarchiveCategory } from "./actions";
import { useEffect, useState } from "react"
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { AddCategoryModal } from "./AddCategoryModal";

export const AdCategoriesModal = ({ onOk, onCancel }) => {
    const [categories, setCategories] = useState();
    const [addAdCategory, setAddAdCategory] = useState(false);
    const [showActive, setShowActive] = useState(true);
    useEffect(() => {
        getAdCategories(true).then(setCategories);
    }, []);

    const onArchive = (categoryId) => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: 'Do you want archive this category?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: async () => {
                message.loading('Archiving category, please wait...', 0);
                await archiveCategory(categoryId);
                message.destroy();
                message.success("Category archived");
                getAdCategories(true).then(setCategories);
            },
        });
    }

    const onUnarchive = (categoryId) => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: 'Do you want unarchive this category?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: async () => {
                message.loading('Unarchiving category, please wait...', 0);
                await unarchiveCategory(categoryId);
                message.destroy();
                message.success("Category unarchived");
                getAdCategories(true).then(setCategories);
            },
        });
    }

    return (
        <Modal title='Ad Categories' visible={true} onOk={onOk} onCancel={onCancel} okText='OK'>
            {addAdCategory && <AddCategoryModal onOk={() => { setAddAdCategory(false); getAdCategories(true).then(setCategories); }}
                onCancel={() => setAddAdCategory(false)} />}
            <Row>
                <Col>
                    <Switch checked={showActive} onChange={setShowActive} /> Show Active Categories Only
                </Col>
                <Col flex="auto" style={{ textAlign: 'end', marginRight: '20px' }}>
                    <Button type="primary" onClick={() => setAddAdCategory(true)}>Add</Button>
                </Col>
            </Row>
            <br />
            <Table
                columns={[
                    {
                        title: 'Category',
                        dataIndex: 'category',
                        key: 'category',
                        align: 'center',
                    },
                    {
                        title: 'Status',
                        dataIndex: 'isArchived',
                        key: 'isArchived',
                        align: 'center',
                        render: isArchived => isArchived ? 'Inactive' : 'Active'
                    },
                    {
                        title: 'Action',
                        dataIndex: 'categoryId',
                        key: 'categoryId',
                        align: 'center',
                        render: (categoryId, item) => (
                            !item.isArchived ? <Button onClick={() => onArchive(categoryId)} type="primary" danger size='small'>Archive</Button>
                                : <Button onClick={() => onUnarchive(categoryId)} type="primary" danger size='small'>Unarchive</Button>
                        ),
                    }
                ]}
                dataSource={showActive ? categories?.filter(c => !c.isArchived) || [] : categories || []}
                loading={categories === undefined}
            />
        </Modal>)
}