import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Form, Input, Button } from 'antd';
import { signIn } from './actions';
import { useDispatch } from 'react-redux';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

export const LoginPage = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { from } = location.state || { from: { pathname: '/' } };

  const onLogin = async () => {
    const { username, password } = form.getFieldsValue();
    const loggedIn = await signIn(username, password, dispatch);
    if (loggedIn) {
      history.replace(from, { from: 'login' });
    }
  };

  return (
    <div style={{ maxWidth: '400px', margin: 'auto', marginTop:'30vh', padding: '20px' }}>
      <h1>CSSL Admin</h1>
      <Form form={form} layout="vertical" requiredMark={false} onFinish={onLogin} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
        <Form.Item name="username" rules={[{ required: true, message: 'Please input your username!' }]}>
          <Input size="large" placeholder="email" prefix={<UserOutlined />} />
        </Form.Item>
        <Form.Item name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
          <Input.Password size="large" placeholder="password" prefix={<LockOutlined />} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">Sign In</Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default LoginPage;
