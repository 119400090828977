import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// Define a type for the slice state
interface UserDataState {
  userId: String,
  tenantId: String,
}

// Define the initial state using that type
const initialState: UserDataState = {
  userId: undefined,
  tenantId: undefined,
};

export const userDataSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    setUserId: (state, action: PayloadAction<String>) => {
      state.userId = action.payload;
    },
    setTenantId: (state, action: PayloadAction<String>) => {
      state.tenantId = action.payload;
    },
  },
});

export const {
  setUserId, setTenantId,
} = userDataSlice.actions;

export default userDataSlice.reducer;
