import { Form, Modal, Input, message } from "antd";
import { createCategory } from "./actions";

export const AddCategoryModal = ({ onOk, onCancel }) => {
    const [form] = Form.useForm();

    const onAdd = async () => {
        try {
            let { category } = await form.validateFields();
            message.loading('Creating category, please wait...', 0);
            let { success, errMsg } = await createCategory(category);
            message.destroy();
            if (success) {
                message.success("Category created");
                onOk();
            }
            else message.error(errMsg);
        } catch (err) {
            message.destroy();
            console.log(err);
            message.error(err?.errorFields?.length ? 'Please fill mandatory fields' : 'Category creation failed.');
        }
    }

    return (
        <Modal title='Add Category' visible={true} onOk={onAdd} onCancel={onCancel} okText='Add'>
            <Form form={form} labelCol={{ span: 9 }} wrapperCol={{ span: 15 }} >
                <Form.Item label="Category Name" name='category' rules={[{ required: true, message: 'Please enter category name!' }]} >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    )
}