import { message } from 'antd';
import { Auth } from 'aws-amplify';
import { setTenantId, setUserId } from '../../redux/userSlice';

export const signIn = async (username, password, dispatch) => {
  message.loading('Signing in. Please wait...', 0);
  try {
    const user = await Auth.signIn(username.trim(), password.trim());
    if(!user.signInUserSession.idToken.payload['cognito:groups']?.includes('AdminUsers')){
      message.destroy();
      message.error('You are not authorised to use this application.');
      await Auth.signOut();
      return;
    }
    
    dispatch(setUserId(user.attributes.sub));
    dispatch(setTenantId(user.attributes['custom:tenantId']));
    message.destroy();
    return true;
  } catch (err) {
    if (err.code === 'UserNotFoundException') {
      message.destroy();
      message.error('Incorrect username or password.');
    } else {
      message.destroy();
      message.error(err.message);
    }
  }
  return false;
};

export const checkIsLoggedIn = async (dispatch) => {
  try {
    const session = await Auth.currentSession();
    if(!session.idToken.payload['cognito:groups'].includes('AdminUsers')){
      message.error('You are not authorised to use this application.');
      await Auth.signOut();
      return;
    }
    dispatch(setUserId(session.getIdToken().payload.sub));
    dispatch(setTenantId(session.getIdToken().payload['custom:tenantId']));
  } catch (err) {
    dispatch(setUserId(null));
  }
};

export const logout = async (dispatch) => {
  message.loading('Signing out. Please wait...', 0);
  try {
    await Auth.signOut();
    dispatch(setUserId(null));
    message.destroy();
  } catch (error) {
    message.destroy();
  }
};
