import { Form, Modal, Input, message, Upload, TimePicker, InputNumber } from "antd";
import { useEffect, useState } from "react";
import { addRestaurant, getSignedUrl } from "./actions";
import { PlusOutlined } from '@ant-design/icons';
import { getBase64, getFileExtension, uploadFile } from "../../utils/commonUtils";
import moment from "moment";

export const moneyRegex = new RegExp(/^[0-9]*(\.[0-9]{1,2})?$/g);
const phoneNoRegex = new RegExp(/^[0-9]{10}$/g);

export const AddRestaurantModal = ({ onOk, onCancel, restaurant }) => {
    const [form] = Form.useForm();
    const [imageFile, setImageFile] = useState();
    const [imageUrl, setImageUrl] = useState();
    const isEdit = restaurant !== undefined;

    useEffect(() => {
        if (isEdit) setImageUrl(restaurant.imageLink);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onAdd = async () => {
        try {
            let { name, contactNo, email, deliveryFee, from, to } = await form.validateFields();
            if (!imageUrl) {
                message.error('Please upload restaurant image');
                return;
            }
            let openFrom = from.format('HH:mm');
            let openTo = to.format('HH:mm');
            message.loading(`${isEdit ? 'Updating' : 'Adding'} restaurant, please wait...`, 0);
            let imageLink, id;
            if (imageFile) {
                let { putUrl, getUrl, restaurantId } = await getSignedUrl(imageFile.type, getFileExtension(imageFile.name), true, restaurant?.restaurantId);
                await uploadFile(putUrl, imageFile, imageFile.type);
                imageLink = getUrl;
                id = restaurantId;
            }
            let { success, errMsg } = await addRestaurant({ ...(isEdit ? restaurant : {}), restaurantId: id || restaurant.restaurantId, name, contactNo, email, deliveryFee: deliveryFee * 100, openFrom, openTo, imageLink: imageLink || imageUrl, isUpdate: isEdit });
            message.destroy();
            if (success) {
                message.success(`Restaurent ${isEdit ? 'updated' : 'added'}`);
                onOk();
            }
            else message.error(errMsg);
        } catch (err) {
            message.destroy();
            console.log(err);
            message.error(err?.errorFields?.length ? 'Please fill mandatory fields' : 'Restaurant adding failed.');
        }
    }
    const beforeUpload = (file, isBottom) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
            return false;
        }
        getBase64(file).then(url => setImageUrl(url));
        setImageFile(file);
        return false;
    }

    return (
        <Modal title={`${isEdit ? 'Edit' : 'Add'} Restaurant`} visible={true} onOk={onAdd} onCancel={onCancel} okText={isEdit ? 'Save' : 'Add'} maskClosable={false}>
            <Form form={form} labelCol={{ span: 9 }} wrapperCol={{ span: 15 }} initialValues={restaurant ? { ...restaurant, from: moment(restaurant.openFrom, 'HH:mm'), to: moment(restaurant.openTo, 'HH:mm'), deliveryFee: restaurant.deliveryFee / 100 } : {}}>
                <Form.Item label="Restaurant Name" name='name' rules={[{ required: true, message: 'Please enter restaurant name!' }]} >
                    <Input />
                </Form.Item>
                <Form.Item label="Contact No (Call/SMS)" name='contactNo' rules={[{ required: true, message: 'Please enter valid phone number!', pattern: phoneNoRegex }]}>
                    <Input placeholder="0777123456" />
                </Form.Item>
                <Form.Item label="Email" name='email' rules={[{ required: true, type: 'email', message: 'Please enter valid email!' }]}>
                    <Input placeholder="abc@def.com" />
                </Form.Item>
                <Form.Item label="Delivery fee" name='deliveryFee' rules={[{ required: true, message: 'Please enter valid delivery fee!', pattern: moneyRegex }]} initialValue={0.00}>
                    <InputNumber addonBefore='Rs.' precision={2} />
                </Form.Item>
                <Form.Item label="Opening time" name='from' rules={[{ required: true, message: 'Please select publishing date!' }]}>
                    <TimePicker format='hh:mm A' />
                </Form.Item>
                <Form.Item label="Closing time" name='to' rules={[{ required: true, message: 'Please select publish end date!' }]}>
                    <TimePicker format='hh:mm A' />
                </Form.Item>
                <Form.Item label="Restaurant Image">
                    <Upload
                        name="avatar"
                        listType="picture-card"
                        showUploadList={false}
                        beforeUpload={file => beforeUpload(file, false)}
                    >
                        {imageUrl ? <img src={imageUrl} alt="img" style={{ width: '100%' }} /> :
                            <div >
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>Upload</div>
                            </div>}
                    </Upload>
                </Form.Item>
            </Form>
        </Modal >
    )
}