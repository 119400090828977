import { Table, Row, Spin, Col, Button, Tag, Modal, message, Switch } from "antd";
import moment from "moment";
import { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import { getAdCategories, getAds, getVendors, setAdInactive } from "./actions";
import { AddAdModal } from "./AddAdModal";
import { AddCategoryModal } from "./AddCategoryModal";
import { AdDetailsModal } from "./AdDetailsModal";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { AdCategoriesModal } from "./AdCategoriesModal";
import { VendorsModal } from "./VendorsModal";


export const AdsPage = () => {
    const [ads, setAds] = useState();
    const [addAd, setAddAd] = useState(false);
    const [addAdCategory, setAddAdCategory] = useState(false);
    const [showAdDetails, setShowAdDetails] = useState(false);
    const [adCategories, setAdCategories] = useState();
    const [viewAdCategories, setViewAdCategories] = useState(false);
    const [viewVendors, setViewVendors] = useState(false);
    const [showActive, setShowActive] = useState(true);
    const [vendors, setVendors] = useState();

    useEffect(() => {
        getAds().then(setAds);
        getAdCategories().then(setAdCategories);
        getVendors().then(setVendors);
    }, []);

    if (ads === undefined) return <Spin size="large" />
    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (title, ad) => <Link to="#" onClick={() => setShowAdDetails(ad)}>{title}</Link>
        },
        {
            title: 'Publish From',
            dataIndex: 'fromDate',
            key: 'fromDate',
            render: from => moment(from).format('YYYY-MM-DD'),
        },
        {
            title: 'Publish To',
            dataIndex: 'toDate',
            key: 'toDate',
            render: to => moment(to).format('YYYY-MM-DD'),
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: createdAt => moment(createdAt).format('YYYY-MM-DD hh:mm A'),
        },
        {
            title: 'Status',
            dataIndex: 'inactivated',
            key: 'inactivated',
            render: (inactivated, ad) => inactivated ? <Tag color="error">Inactivated</Tag> : moment(ad.fromDate).startOf('day').isAfter(moment()) ? <Tag color="processing">Sheduled</Tag> : moment(ad.toDate).endOf('day').isAfter(moment()) ? <Tag color="success">Active</Tag> : <Tag color="error">Finished</Tag>
        },
        {
            title: 'Ad Clicks',
            dataIndex: 'clicks',
            key: 'clicks',
            align: 'center',
            render: (clicks) => clicks?.length || 0
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, ad) => (
                <Button onClick={() => onInactive(ad.adId)} type="primary" danger size='small' disabled={ad.inactivated || moment(ad.toDate).endOf('day').isBefore(moment())}>Make Inactive</Button>
            ),
        },
    ];

    const onInactive = (adId) => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: 'Do you want to make this ad inactive?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: async () => {
                message.loading('Updating ad status, please wait...', 0);
                await setAdInactive(adId);
                message.destroy();
                message.success("Ad inactivated");
                getAds().then(setAds);
            },
        });
    }

    return <>
        {addAd && <AddAdModal onOk={() => { getAds().then(setAds); setAddAd(false) }}
            onCancel={() => setAddAd(false)} adCategories={adCategories} vendors={vendors} setAddAdCategory={setAddAdCategory} />}
        {addAdCategory && <AddCategoryModal onOk={() => { setAddAdCategory(false); getAdCategories().then(setAdCategories); }}
            onCancel={() => setAddAdCategory(false)} />}
        {showAdDetails && <AdDetailsModal onCancel={() => setShowAdDetails()} ad={showAdDetails} />}
        {viewAdCategories && <AdCategoriesModal onOk={() => { setViewAdCategories(false); getAdCategories().then(setAdCategories); }}
            onCancel={() => setViewAdCategories(false)} />}
        {viewVendors && <VendorsModal onOk={() => { setViewVendors(false); getVendors().then(setVendors); }}
            onCancel={() => setViewVendors(false)} />}
        <Row>
            <Col>
                <Switch checked={showActive} onChange={setShowActive} /> Show Active Ads Only
            </Col>
            <Col flex="auto" style={{ textAlign: 'end', marginRight: '20px' }}>
                <Button type="primary" onClick={() => setViewVendors(true)}>Vendors</Button>&nbsp;
                <Button type="primary" onClick={() => setViewAdCategories(true)}>Categories</Button>&nbsp;
                <Button type="primary" onClick={() => setAddAd(true)}>New Ad</Button>
            </Col>
        </Row>
        <br />
        <Row>
            <Table columns={columns} dataSource={showActive ? ads.filter(ad => !ad.inactivated && moment(ad.toDate).endOf('day').isAfter(moment())) : ads} style={{ width: "100%" }} rowKey="adId" />
        </Row>
    </>
}