import { Form, Modal, Input, message, DatePicker, Upload, Switch, Select, Spin, Row, Button } from "antd";
import { useState } from "react";
import { createAd, getSignedUrl } from "./actions";
import { PlusOutlined, PlusCircleOutlined } from '@ant-design/icons';
import axios from 'axios';

export const AddAdModal = ({ onOk, onCancel, adCategories, setAddAdCategory, vendors }) => {
    const [form] = Form.useForm();
    const [imageFile, setImageFile] = useState();
    const [bottomImageFile, setBottomImageFile] = useState();
    const [imageUrl, setImageUrl] = useState();
    const [bottomImageUrl, setBottomImageUrl] = useState();
    const [sendPush, setSendPush] = useState(false);

    if (adCategories === undefined || vendors === undefined) return <Spin size="large" />;

    const onAdd = async () => {
        try {
            let { title, description, from, to, link, sendPush, notificationTimes, contactNo, videoLink, categories, vendorId } = await form.validateFields();
            if (!imageFile && !bottomImageFile) {
                message.error('Please upload at least one image');
                return;
            }
            let fromDate = from.format('YYYY-MM-DD');
            let toDate = to.format('YYYY-MM-DD');
            message.loading('Publishing ad, please wait...', 0);
            let mainImageLink, bottomImageLink, id;
            if (imageFile) {
                let { putUrl, getUrl, adId } = await getSignedUrl(imageFile.type, getFileExtension(imageFile.name), true, id);
                await uploadFile(putUrl, imageFile, imageFile.type);
                mainImageLink = getUrl;
                id = adId;
            }
            if (bottomImageFile) {
                let { putUrl, getUrl, adId } = await getSignedUrl(bottomImageFile.type, getFileExtension(bottomImageFile.name), false, id);
                await uploadFile(putUrl, bottomImageFile, bottomImageFile.type);
                bottomImageLink = getUrl;
                id = adId;
            }
            let { success, errMsg } = await createAd({
                adId: id, title, description, fromDate, toDate, mainImageLink, bottomImageLink, link,
                sendPush, notificationTimes, contactNo, videoLink, categories, vendorId
            });
            message.destroy();
            if (success) {
                message.success("Ad published");
                onOk();
            }
            else message.error(errMsg);
        } catch (err) {
            message.destroy();
            console.log(err);
            message.error(err?.errorFields?.length ? 'Please fill mandatory fields' : 'Ad publishing failed.');
        }
    }
    const beforeUpload = (file, isBottom) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
            return false;
        }
        getBase64(file).then(url => isBottom ? setBottomImageUrl(url) : setImageUrl(url));
        if (isBottom) {
            setBottomImageFile(file);
        } else {
            setImageFile(file);
        }
        return false;
    }

    return (
        <Modal title='Publish Ad' visible={true} onOk={onAdd} onCancel={onCancel} okText='Publish' maskClosable={false}>
            <Form form={form} labelCol={{ span: 9 }} wrapperCol={{ span: 15 }} >
                <Form.Item label="Title" name='title' rules={[{ required: true, message: 'Please enter ad title!' }]} >
                    <Input />
                </Form.Item>
                <Form.Item label="Description" name='description' rules={[{ required: true, message: 'Please enter ad description!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item label="Vendor" style={{marginBottom: 'unset'}}>
                    <Row>
                        <Form.Item name='vendorId' rules={[{ required: true, message: 'Please select vendor!' }]}>
                            <Select style={{ minWidth: '180px' }}>
                                {vendors.map(v => <Select.Option value={v.vendorId}>{v.vendor}</Select.Option>)}
                            </Select>
                        </Form.Item>
                        <Button icon={<PlusCircleOutlined />} onClick={() => setAddAdCategory(true)}>Add</Button>
                    </Row>
                </Form.Item>
                <Form.Item label="Categories" style={{marginBottom: 'unset'}}>
                    <Row>
                        <Form.Item name='categories' rules={[{ required: true, message: 'Please select ad category!' }]}>
                            <Select style={{ minWidth: '180px' }} mode="multiple">
                                {adCategories.map(cat => <Select.Option value={cat.categoryId}>{cat.category}</Select.Option>)}
                            </Select>
                        </Form.Item>
                        <Button icon={<PlusCircleOutlined />} onClick={() => setAddAdCategory(true)}>Add</Button>
                    </Row>
                </Form.Item>
                <Form.Item label="Publish From" name='from' rules={[{ required: true, message: 'Please select publishing date!' }]}>
                    <DatePicker />
                </Form.Item>
                <Form.Item label="Publish to" name='to' rules={[{ required: true, message: 'Please select publish end date!' }]}>
                    <DatePicker />
                </Form.Item>
                <Form.Item label="Link (On Click)" name='link' rules={[{ required: true, type: 'url', message: 'Please enter valid link!' }]}>
                    <Input placeholder="https://www.google.com" />
                </Form.Item>
                <Form.Item label="Contact No (Call/SMS)" name='contactNo'>
                    <Input />
                </Form.Item>
                <Form.Item label="Video LInk" name='videoLink' rules={[{ type: 'url', message: 'Please enter valid link!' }]}>
                    <Input placeholder="https://www.youtube.com/watch?v=JSXIdbwf_cc" />
                </Form.Item>
                <Form.Item label="Main Image">
                    <Upload
                        name="avatar"
                        listType="picture-card"
                        showUploadList={false}
                        beforeUpload={file => beforeUpload(file, false)}
                    >
                        {imageUrl ? <img src={imageUrl} alt="img" style={{ height: '100%' }} /> :
                            <div >
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>Upload</div>
                            </div>}
                    </Upload>
                </Form.Item>
                <Form.Item label="Bottom Bar Image">
                    <Upload
                        name="avatar"
                        listType="picture-card"
                        showUploadList={false}
                        beforeUpload={file => beforeUpload(file, true)}
                    >
                        {bottomImageUrl ? <img src={bottomImageUrl} alt="img" style={{ width: '100%' }} /> :
                            <div >
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>Upload</div>
                            </div>}
                    </Upload>
                </Form.Item>
                <Form.Item label="Send Push Notifications?" name='sendPush' valuePropName="checked">
                    <Switch onChange={setSendPush} />
                </Form.Item>
                {sendPush &&
                    <Form.Item label="Notification Times" name='notificationTimes' rules={[{ required: true, message: 'Please select time to send push notifications!' }]}>
                        <Select mode="tags" placeholder="Please select time" style={{ width: '100%' }}>
                            {[...Array(24).keys()].map(i => <Select.Option key={i}>{i === 0 ? 12 : i > 12 ? i % 12 : i}:00 {i >= 12 ? 'PM' : 'AM'}</Select.Option>)}
                        </Select>
                    </Form.Item>
                }
            </Form>
        </Modal >
    )
}

const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

const getFileExtension = (filename) => {
    return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename)[0] : undefined;
}

export const uploadFile = async (url, file, contentType) => {
    return await axios.put(url,
        file,
        {
            headers: {
                'Content-Type': contentType
            }
        }
    );
}