import { Modal, Descriptions, Image, Tag } from "antd";
import moment from "moment";

export const AdDetailsModal = ({ onCancel, ad }) => {

    return (
        <Modal visible={true} onOk={onCancel} onCancel={onCancel} >
            <Descriptions title="Ad Details" bordered column={1}>
                <Descriptions.Item label="Title">{ad.title}</Descriptions.Item>
                <Descriptions.Item label="Description">{ad.description}</Descriptions.Item>
                <Descriptions.Item label="Categories">{ad.adCategories}</Descriptions.Item>
                <Descriptions.Item label="Publish From">{moment(ad.fromDate).format('YYYY-MM-DD')}</Descriptions.Item>
                <Descriptions.Item label="Publish To">{moment(ad.toDate).format('YYYY-MM-DD')}</Descriptions.Item>
                <Descriptions.Item label="Link (On Click)"><a href={ad.link}>{ad.link}</a></Descriptions.Item>
                <Descriptions.Item label="Contact No (Call/SMS)">{ad.contactNo}</Descriptions.Item>
                <Descriptions.Item label="Video LInk"><a href={ad.videoLink}>{ad.videoLink}</a></Descriptions.Item>
                <Descriptions.Item label="Main Image"><Image src={ad.mainImageLink} width="70px" /></Descriptions.Item>
                <Descriptions.Item label="Bottom Bar Image">{ad.bottomImageLink ? <Image src={ad.bottomImageLink} width="70px" /> : "No Image"}</Descriptions.Item>
                <Descriptions.Item label="Push Notifications">{ad.sendPush ? 'Enabled' : 'Disabled'}</Descriptions.Item>
                {ad.sendPush && <>
                    <Descriptions.Item label="Push Notification Time">{ad.notificationTimes.map(time => <Tag>{time}:00</Tag>)}</Descriptions.Item>
                </>}
            </Descriptions>
        </Modal >
    )
}